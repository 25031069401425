<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row v-if="isAdminUser && isOwner">
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 font-weight-medium text-center">
          Subscripción
        </h3>
        <p class="ma-0 mt-2 ml-1 text-justify">
          Para poder seguir disfrutando de SSENCIAL es necesario que realices el
          pago correspondiente a tu plan seleccionado, tus pagos son 100%
          seguros ya que se realizan con el procesador de pagos Stripe
        </p>
      </v-col>
      <v-col cols="12">
        <div class="text-center" v-if="loader">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
        <v-expand-transition>
          <v-card
            v-if="!loader"
            outlined
            class="text-center d-flex flex-column justify-space-between"
            :max-width="$vuetify.breakpoint.width < 673 ? '100%' : '320'"
          >
            <v-container class="px-5">
              <div class="d-flex align-center justify-center my-3 mb-4">
                <img
                  src="@/assets/ssencial-logo.svg"
                  alt="ssencial"
                  height="24px"
                />
                <p class="text-h4 ma-0 ml-1">{{ plan.type }}</p>
              </div>
              <p class="ma-0 font-weight-light">{{ plan.description }}</p>
            </v-container>
            <v-container class="pb-0 px-5">
              <template v-for="(feature, index) in plan.features">
                <p class="ma-0 font-weight-medium" :key="index + '-title'">
                  {{ feature.title }}
                </p>
                <p
                  :key="index + '.-desc'"
                  class="caption mb-0"
                  :class="{ 'mb-3': plan.features.length > index + 1 }"
                >
                  {{ feature.decription }}
                </p>
              </template>
            </v-container>
            <v-container class="px-5">
              <div class="d-flex justify-center align-center">
                <p class="font-weight-light ma-0 ml-4 mr-1">$</p>
                <p class="ma-0">
                  <span class="text-h4">{{ plan.price }}</span>
                  <span class="caption font-weight-medium">MXN</span>
                </p>
              </div>
              <div class="ml-3 caption" style="margin-top: -10px !important;">
                Mensual
              </div>
              <v-btn
                @click="paymentCheckout"
                block
                color="#4d90fe"
                class="mt-4 white--text pulse-on"
              >
                Proceder al pago
              </v-btn>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
      <v-col cols="12" class="text-center">
        <p class="mb-1">
          ¿Tienes problemas con tu pago?, contacta a soporte para ayudarte.
        </p>
        <v-btn small outlined href="mailto:edwin@ssencial.com">Contactar</v-btn>
      </v-col>
      <v-col cols="12">
        <p class="caption ma-0 text-center text--secondary">
          De antemano muchas gracias por formar parte de la familia
          <span class="text--primary">SSENCIAL</span>
        </p>
        <p class="ma-0 text-center">❤️</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 font-weight-medium text-center">
          Subscripción
        </h3>
        <p class="ma-0 mt-2 ml-1 text-justify">
          Para poder seguir disfrutando de SSENCIAL es necesario que el
          Administrador y dueño del negocio realice el pago correspondiente al
          plan que eligió, notifica a tu administrador de dicho estatus o
          contacta con soporte para mas información.
        </p>
      </v-col>
      <v-col cols="12">
        <p class="mb-2 text-center">Plan actual contradado</p>
        <v-expand-transition>
          <v-card
            v-if="!loader"
            outlined
            class="text-center d-flex flex-column justify-space-between"
            :max-width="$vuetify.breakpoint.width < 673 ? '100%' : '320'"
          >
            <v-container class="px-5">
              <div class="d-flex align-center justify-center my-3 mb-4">
                <img
                  v-if="$vuetify.theme.dark"
                  src="@/assets/ssencial-logo-white.svg"
                  alt="ssencial"
                  height="24px"
                />
                <img
                  v-else
                  src="@/assets/ssencial-logo.svg"
                  alt="ssencial"
                  height="24px"
                />
                <p class="text-h4 ma-0 ml-1">{{ plan.type }}</p>
              </div>
              <p class="ma-0 font-weight-light">{{ plan.description }}</p>
            </v-container>
            <v-container class="pb-0 px-5">
              <template v-for="(feature, index) in plan.features">
                <p class="ma-0 font-weight-medium" :key="index + '-title'">
                  {{ feature.title }}
                </p>
                <p
                  :key="index + '.-desc'"
                  class="caption mb-0"
                  :class="{ 'mb-3': plan.features.length > index + 1 }"
                >
                  {{ feature.decription }}
                </p>
              </template>
            </v-container>
            <v-container class="px-5">
              <div class="d-flex justify-center align-center">
                <p class="font-weight-light ma-0 ml-4 mr-1">$</p>
                <p class="ma-0">
                  <span class="text-h4">{{ plan.price }}</span>
                  <span class="caption font-weight-medium">MXN</span>
                </p>
              </div>
              <div class="ml-3 caption" style="margin-top: -10px !important;">
                Mensual
              </div>
            </v-container>
          </v-card>
        </v-expand-transition>
      </v-col>
      <v-col cols="12">
        <p class="caption ma-0 text-center text--secondary">
          De antemano muchas gracias por formar parte de la familia
          <span class="text--primary">SSENCIAL</span>
        </p>
        <p class="ma-0 text-center">❤️</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { firestore } from "@/config/firebase";
import { mapActions, mapGetters } from "vuex";
import { app } from "@/config/globals";
import {
  loaderDone,
  loaderStart,
  showMessageLoader
} from "@/services/LoaderService";

export default {
  data() {
    return {
      plan: null,
      loader: true
    };
  },
  beforeMount() {
    this.getCurrentPlanData();
  },
  methods: {
    ...mapActions("business", ["getBusinessPlan", "getPlanData"]),
    async getCurrentPlanData() {
      this.loader = true;
      if (this.getBusinessPlanID) {
        this.plan = await this.getPlanData(this.getBusinessPlanID);
        this.loader = false;
      } else {
        const planData = await this.getBusinessPlan();
        this.plan = await this.getPlanData(planData.id);
        this.loader = false;
      }
    },
    async paymentCheckout() {
      loaderStart();
      showMessageLoader(
        "Espera un momento en lo que te redireccionamos a tu pago"
      );
      const docRef = await firestore("customers")
        .doc(this.currentUid)
        .collection("checkout_sessions")
        .add({
          price: this.getBusinessMetadaPlan.price_id,
          success_url: window.location.origin + "/payment-successful",
          cancel_url: window.location.origin + "/payment-status"
        });
      docRef.onSnapshot(async snap => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`Ah ocurrido un error: ${error.message}`);
          loaderDone();
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(app.stripe_key);
          stripe.redirectToCheckout({ sessionId });
          loaderDone();
        }
      });
    }
  },
  computed: {
    ...mapGetters("user", ["currentUid", "isAdminUser", "isOwner"]),
    ...mapGetters("business", ["getBusinessPlanID", "getBusinessMetadaPlan"])
  }
};
</script>

<style scoped>
.pulse-on {
  animation: shadow-pulse 1.5s infinite;
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}
</style>
